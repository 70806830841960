<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth/auth.store";
import PasswordInput from "~/components/Pages/Auth/steps/PasswordInput.vue";
import { useAuthValidation } from "~/composables/auth/useAuthValidation";
import type { IToken } from "~/utils/interfaces/auth-interfaces";
import { useAuthApi } from "~/composables/auth/useAuthApi";
import { useTokens } from "~/composables/auth/useTokens";
import { useAuth } from "~/composables/auth/useAuth";
import { useFeedback } from "~/composables/feedback/useFeedback";

const authStore = useAuthStore();
const tokens = useTokens();
const { connexion } = storeToRefs(authStore);
const auth = useAuth();
const authApi = useAuthApi();
const authValidation = useAuthValidation();
const feedback = useFeedback();

const $e = await authValidation.stepLoginPassword();

const inputPassword = ref<{ inputPassword: HTMLInputElement } | null>(null);
const $error = ref<string | null>();

onMounted(() => {
  if (inputPassword.value?.inputPassword) {
    inputPassword.value?.inputPassword.focus();
  }
});

watch($e, () => {
  $error.value = null;
  if ($e.value.$error) {
    $error.value = $e.value.$errors.find(Boolean)?.$message as string;
  }
});

async function clickLoginPassword() {
  await $e.value.$validate();

  if ($e.value.$error) {
    return;
  }

  if (connexion.value.login_email && connexion.value.login_password) {
    const hashedPassword = auth.hashPassword(connexion.value.login_password);
    const { data, error } = await authApi.login(
      connexion.value.login_email,
      hashedPassword
    );

    if (!data.value) {
      $error.value =
        "Ce mot de passe ne correspond pas à l’adresse email indiquée.";
      return;
    }

    await authStore.setTokens(data.value as IToken);
    await authStore.login();
    return auth.handleExternalRedirect();
  }
}

async function resetPassword() {
  if (connexion.value.login_email) {
    const { data, error } = await authApi.generateResetPassword(
      connexion.value.login_email
    );

    if (!data.value) {
      feedback.error(`Une erreur est survenue`, "small");
      return;
    }

    connexion.value.show_reset_password_sent = true;
  }
}
</script>

<template>
  <form class="connexion__form" @submit.prevent="clickLoginPassword">
    <div class="connexion__form_input_container">
      <label for="connexion_password" class="body_med text-secondary-color"
        >Mot de passe *</label
      >
      <PasswordInput
        id="connexion_password"
        ref="inputPassword"
        v-model="connexion.login_password"
        :data-error="!!$error"
        placeholder="********"
      ></PasswordInput>
      <p v-if="$error" class="text-primary-red-color body_med">
        {{ $error }}
      </p>
    </div>

    <div class="connexion__form_button_container">
      <button class="button__default">Se connecter</button>
      <button
        class="body_med text-primary-blue-color"
        @click.prevent="resetPassword"
      >
        Mot de passe oublié ?
      </button>
    </div>
  </form>
</template>

<style lang="scss" scoped></style>
