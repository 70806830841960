<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth/auth.store";
import { useAuthValidation } from "~/composables/auth/useAuthValidation";
import { useAuthApi } from "~/composables/auth/useAuthApi";
import { useAuth } from "~/composables/auth/useAuth";
import type { IToken } from "~/utils/interfaces/auth-interfaces";
import { useFeedback } from "~/composables/feedback/useFeedback";
import RegisterPasswordBase from "~/components/Pages/Auth/steps/RegisterPasswordBase.vue";

const authStore = useAuthStore();
const authApi = useAuthApi();
const auth = useAuth();
const { connexion } = storeToRefs(authStore);
const authValidation = useAuthValidation();
const feedback = useFeedback();

const $e = await authValidation.stepRegisterPassword();

async function clickLoginPassword() {
  await $e.value.$validate();

  if ($e.value.$error) {
    return;
  }

  if (connexion.value.login_email && connexion.value.register_password_first) {
    const hashedPassword = auth.hashPassword(connexion.value.register_password_first);
    const { data } = await authApi.register(connexion.value.login_email, hashedPassword);

    if (!data.value) {
      feedback.error(`Une erreur est survenue`, "small");
    }

    await authStore.setTokens(data.value as IToken);
    await authStore.login();
    return auth.handleRedirect();
  }
}
</script>

<template>
  <form class="connexion__form" @submit.prevent="clickLoginPassword">
    <RegisterPasswordBase></RegisterPasswordBase>
    <div class="connexion__form_button_container">
      <button class="button__default">Créer mon compte</button>
    </div>
  </form>
</template>

<style lang="scss" scoped></style>
