<script setup lang="ts">
import { storeToRefs } from "pinia";
import { CONNEXION_STEPS } from "~/utils/enums/auth.enums";
import { useAuthStore } from "~/store/auth/auth.store";

const authStore = useAuthStore();
const { connexion } = storeToRefs(authStore);
</script>

<template>
  <div class="connexion__container_header">
    <img style="width: 48px; height: 48px" src="/icons/simbel.png" alt="" />

    <div v-if="connexion.connexion_step === CONNEXION_STEPS.RESET_PASSWORD">
      <div class="connexion__container_header_msg">
        <h1 class="heading_1">Réinitialiser votre mot de passe</h1>
      </div>
    </div>

    <div v-if="connexion.connexion_step === CONNEXION_STEPS.LOGIN_EMAIL">
      <div class="connexion__container_header_msg">
        <h1 class="heading_1">Bonjour</h1>
        <img src="/icons/emojis/main_hello.svg" alt="" />
      </div>
    </div>

    <div v-if="connexion.connexion_step === CONNEXION_STEPS.LOGIN_PASSWORD">
      <div class="connexion__container_header_msg">
        <h1 class="heading_1">Bonjour</h1>
        <img src="/icons/emojis/main_hello.svg" alt="" />
      </div>
      <span class="body_med text-tertiary-color"> Ravis de vous revoir ! </span>
    </div>

    <div v-if="connexion.connexion_step === CONNEXION_STEPS.VALIDATE_EMAIL">
      <div class="connexion__container_header_msg">
        <h1 class="heading_1">Un email de confirmation a ete envoye</h1>
        <img src="/icons/emojis/main_hello.svg" alt="" />
      </div>
      <span class="body_med text-tertiary-color"> Veuillez confirmer votre email </span>
    </div>

    <div v-if="
      connexion.connexion_step === CONNEXION_STEPS.REGISTER_PASSWORD ||
      connexion.connexion_step === CONNEXION_STEPS.REGISTER_NAME
    ">
      <div class="connexion__container_header_msg">
        <h1 class="heading_1">Bienvenue</h1>
        <img src="/icons/emojis/fusée.svg" alt="" />
      </div>
      <span class="body_med text-tertiary-color"> Complétez votre profil </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.connexion__container_header {
  @apply flex flex-col items-center gap-[16px];
}

.connexion__container_header_msg {
  @apply flex flex-row gap-[12px] items-center;

  img {
    width: 24px;
    height: 24px;
  }
}
</style>
