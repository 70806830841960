import { useFetchApi } from "~/composables/api/useFetchApi";

export const useAuthSSOApi = () => {
  async function getCompanySSOProvider(company_id: string) {
    return await useFetchApi("auth", `/ssoProviders/companySsoProvider/${company_id}`, {
      method: "GET",
    });
  }

  async function getSsoProviderCallBackUrl(external_id: string) {
    return await useFetchApi("auth", `/ssoProviders/callBackUrl/${external_id}`, {
      method: "GET",
    });
  }

  return {
    getCompanySSOProvider,
    getSsoProviderCallBackUrl,
  };
};
