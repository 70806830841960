<script setup lang="ts">
import { storeToRefs } from "pinia";
import CenterModal from "~/components/Common/Modals/CenterModal.vue";
import { useAuthStore } from "~/store/auth/auth.store";
import { useAuthApi } from "~/composables/auth/useAuthApi";

const authStore = useAuthStore();
const authApi = useAuthApi();
const { connexion } = storeToRefs(authStore);

async function done() {
    connexion.value.show_reset_password_sent = false;
}
</script>

<template>
    <CenterModal :style="{ 'max-width': '488px', width: '100%' }">
        <template #body>
            <div class="flex flex-col items-center text-center py-[24px] px-[24px]">
                <img src="/icons/emojis/fusée.svg" alt="" :style="{ height: '64px', width: '64px' }" />
                <h4 class="heading_3_bold pt-[8px]">On est tout bon !</h4>
                <p class="body_med text-secondary-color pt-[16px]">
                    Vous devriez bientôt recevoir un e-mail à l'adresse {{ connexion.login_email }} pour savoir comment
                    ré-initialiser votre mot de passe.
                </p>
                <div class="flex justify-end gap-[16px] pt-[16px]">
                    <button class="button__default" @click="done">Ok</button>
                </div>
            </div>
        </template>
    </CenterModal>
</template>

<style scoped lang="scss"></style>
