<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useRoute } from "#app";
import { definePageMeta } from "#imports";
import ConnexionLayout from "~/components/Pages/Auth/ConnexionLayout.vue";
import { CONNEXION_STEPS } from "~/utils/enums/auth.enums";
import ConnexionHeader from "~/components/Pages/Auth/ConnexionHeader.vue";
import LoginEmail from "~/components/Pages/Auth/steps/LoginEmail.vue";
import LoginPassword from "~/components/Pages/Auth/steps/LoginPassword.vue";
import { useAuthStore } from "~/store/auth/auth.store";
import RegisterPassword from "~/components/Pages/Auth/steps/RegisterPassword.vue";
import RegisterName from "~/components/Pages/Auth/steps/RegisterName.vue";
import ResetPasswordSentModal from "~/components/Pages/Auth/ResetPasswordSentModal.vue";
import FirstPasswordSentModal from "~/components/Pages/Auth/FirstPasswordSentModal.vue";
import ResetPassword from "~/components/Pages/Auth/steps/ResetPassword.vue";
import { useHeader } from "~/composables/header/useHeader";

definePageMeta({
  layout: "guest",
  isLoggedIn: false,
});

const route = useRoute();
const header = useHeader();
const authStore = useAuthStore();

const { connexion } = storeToRefs(authStore);

if (authStore.isLoggedIn()) {
  navigateTo("/home");
}

header.pageName.value = "";
authStore.$reset();

if (route.query?.reset_token) {
  connexion.value.connexion_step = CONNEXION_STEPS.RESET_PASSWORD;
}
</script>

<template>
  <ConnexionLayout>
    <template #head>
      <ConnexionHeader></ConnexionHeader>
    </template>

    <template #body>
      <LoginEmail v-if="connexion.connexion_step === CONNEXION_STEPS.LOGIN_EMAIL"></LoginEmail>
      <LoginPassword v-if="connexion.connexion_step === CONNEXION_STEPS.LOGIN_PASSWORD"></LoginPassword>
      <RegisterPassword v-if="connexion.connexion_step === CONNEXION_STEPS.REGISTER_PASSWORD"></RegisterPassword>
      <RegisterName v-if="connexion.connexion_step === CONNEXION_STEPS.REGISTER_NAME"></RegisterName>
      <ResetPassword v-if="connexion.connexion_step === CONNEXION_STEPS.RESET_PASSWORD"></ResetPassword>
      <ResetPasswordSentModal v-if="connexion.show_reset_password_sent"></ResetPasswordSentModal>
      <FirstPasswordSentModal v-if="connexion.show_first_password_sent"></FirstPasswordSentModal>
    </template>
  </ConnexionLayout>
</template>

<style scoped></style>
