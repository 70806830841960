<script setup lang="ts">

import { useAuthStore } from "~/store/auth/auth.store";

const authStore = useAuthStore()

</script>

<template>
  <div class="connexion__layout">
    <div class="connexion__container">
      <slot name="head"></slot>
      <slot name="body"></slot>
    </div>
  </div>
</template>

<style scoped>

.connexion__layout {
    @apply flex justify-center pt-[120px] pb-[60px];
}

.connexion__container {
    @apply flex flex-col p-[32px] justify-center gap-[32px];
    width: 502px;
    border: 2px solid var(--grey-lightest);
    border-radius: 8px;
}

</style>
