import { storeToRefs } from "pinia";

import useVuelidate from "@vuelidate/core";
import { email, helpers, required, sameAs } from "@vuelidate/validators";
import { useAuthStore } from "~/store/auth/auth.store";

export const useAuthValidation = () => {
  function stepLoginEmail() {
    const authStore = useAuthStore();
    const { connexion } = storeToRefs(authStore);

    const rules = computed(() => {
      return {
        login_email: {
          required: helpers.withMessage("Ce champ est obligatoire", required),
          email: helpers.withMessage("Email invalide", email),
        },
      };
    });

    return useVuelidate(rules, connexion.value);
  }

  function stepLoginPassword() {
    const authStore = useAuthStore();
    const { connexion } = storeToRefs(authStore);

    const rules = computed(() => {
      return {
        login_password: {
          required: helpers.withMessage("Ce champ est obligatoire", required),
        },
      };
    });

    return useVuelidate(rules, connexion.value);
  }

  function stepRegisterPassword() {
    const authStore = useAuthStore();
    const { connexion } = storeToRefs(authStore);

    const mustContainOneNumber = (value: string) => "0123456789".split("").some((char) => value.includes(char));
    const mustContainUppercase = (value: string) => value !== value.toLowerCase();
    const mustContainLowercase = (value: string) => value !== value.toUpperCase();
    const mustContainSpecialChar = (value: string) =>
      "[`!@#$%^&*()_+-=[]{};':\"\\|,.<>/?~]/".split("").some((char) => value.includes(char));
    const mustContainMinLength = (value: string) => value.length >= 8;

    const rules = computed(() => {
      return {
        register_password_first: {
          required,
          mustContainMinLength,
          mustContainOneNumber,
          mustContainUppercase,
          mustContainLowercase,
          mustContainSpecialChar,
        },
        register_password_confirm: {
          required,
          sameAsPassword: sameAs(connexion.value.register_password_first),
        },
      };
    });

    return useVuelidate(rules, connexion.value);
  }

  function stepRegisterName() {
    const authStore = useAuthStore();
    const { connexion } = storeToRefs(authStore);

    const rules = computed(() => {
      return {
        firstname: {
          required: helpers.withMessage("Ce champ est obligatoire", required),
        },
        lastname: {
          required: helpers.withMessage("Ce champ est obligatoire", required),
        },
      };
    });

    return useVuelidate(rules, connexion.value);
  }

  return {
    stepLoginEmail,
    stepLoginPassword,
    stepRegisterPassword,
    stepRegisterName,
  };
};
