<script setup lang="ts">

import PasswordInput from "~/components/Pages/Auth/steps/PasswordInput.vue";
import { useAuthStore } from "~/store/auth/auth.store";
import { storeToRefs } from "pinia";
import { useAuthValidation } from "~/composables/auth/useAuthValidation";

const authStore = useAuthStore()
const { connexion } = storeToRefs(authStore)
const authValidation = useAuthValidation()
const $e = await authValidation.stepRegisterPassword()

const inputPassword = ref()

onMounted(() => {
    if (inputPassword.value?.inputPassword) {
        inputPassword.value.inputPassword.focus()
    }
})

</script>

<template>
    <div class="grid gap-[16px]">
        <div class="connexion__form_input_container">
            <label for="password" class="body_med text-secondary-color">Mot de passe *</label>
            <PasswordInput v-model="connexion.register_password_first" id="password" ref="inputPassword" placeholder="********"></PasswordInput>
        </div>

        <div v-if="$e.$error" class="validator__container">
          <span>
              <img v-if="$e.$errors.some(x => x.$validator === 'mustContainMinLength')" src="/icons/emojis/cancel.svg" alt="">
              <img v-else src="/icons/emojis/check.svg" alt="">
              <span class="meta">8 caractères minimum</span>
          </span>
          <span>
              <img v-if="$e.$errors.some(x => x.$validator === 'mustContainOneNumber')" src="/icons/emojis/cancel.svg" alt="">
              <img v-else src="/icons/emojis/check.svg" alt="">
              <span class="meta">1 chiffre minimum</span>
          </span>
          <span>
              <img v-if="$e.$errors.some(x => x.$validator === 'mustContainLowercase')" src="/icons/emojis/cancel.svg" alt="">
              <img v-else src="/icons/emojis/check.svg" alt="">
              <span class="meta">1 lettre minuscule minimum</span>
          </span>
          <span>
              <img v-if="$e.$errors.some(x => x.$validator === 'mustContainUppercase')" src="/icons/emojis/cancel.svg" alt="">
              <img v-else src="/icons/emojis/check.svg" alt="">
              <span class="meta">1 lettre majuscule minimum</span>
          </span>
          <span>
              <img v-if="$e.$errors.some(x => x.$validator === 'mustContainSpecialChar')" src="/icons/emojis/cancel.svg" alt="">
              <img v-else src="/icons/emojis/check.svg" alt="">
              <span class="meta">1 caractère spécial minimum (&, @, $, % , etc)</span>
          </span>
        </div>

        <div class="connexion__form_input_container">
            <label for="password_confirm" class="body_med text-secondary-color">Confirmez votre mot de passe *</label>
            <PasswordInput v-model="connexion.register_password_confirm" id="password_confirm" placeholder="********"></PasswordInput>
            <span v-if="$e.$errors.some(x => x.$validator === 'required')" class="text-primary-red-color body_med">
          Ce champ est obligatoire
        </span>
            <span v-else-if="$e.$errors.some(x => x.$validator === 'sameAsPassword')" class="text-primary-red-color body_med">
          Les deux mots de passe sont différents.
        </span>
        </div>
    </div>
</template>


<style scoped lang="scss">
@import "assets/scss/texts";

.validator__container {

  & > * {
    @extend .meta;
    @apply flex gap-[12px] items-center;
  }
  
  img {
    width: 12px;
    height: 12px;
  }
}

</style>