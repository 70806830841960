<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">

import SvgIcon from "~/components/Common/SvgIcon.vue";
import type { ComputedRef, Ref } from "vue";

const props = defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);


type TInputPassword = "password" | "text"
const inputType: Ref<boolean> = ref(true)

const getType: ComputedRef<TInputPassword> = computed(() => {
  return inputType.value ? "password" : "text"
})

const inputPassword = ref(null)

defineExpose({ inputPassword })

function triggerInputPassword() {
  inputType.value = !inputType.value
}

</script>

<template>
  <div class="relative">
    <input ref="inputPassword" @input.stop="$emit('update:modelValue', ($event.target as HTMLInputElement).value)" v-bind="$attrs" :type="getType" class="password__input input__default body_med">
    <button tabindex="-1" type="button" @click.stop="triggerInputPassword">
      <SvgIcon v-show="inputType" class="password__eye text-secondary-color" name="see"></SvgIcon>
      <SvgIcon v-show="!inputType" class="password__eye text-secondary-color" name="eyes_hide"></SvgIcon>
    </button>
  </div>
</template>

<style lang="scss" scoped>

.password__input {
  @apply w-full;
  padding-right: calc(8px + 24px + 8px)
}

.password__eye {
  @apply absolute;
  top: 0;
  bottom: 0;
  right: 8px;
  margin: auto 0;
  height: 24px;
  width: 24px;
  max-width: 24px;
  max-height: 24px;

}

.connexion__container_header {
  @apply flex flex-col items-center gap-[16px];
}

.connexion__container_header_msg {
  @apply flex flex-row gap-[12px];
}

</style>