<script setup lang="ts">

import { useAuthStore } from "~/store/auth/auth.store";
import { useAuthValidation } from "~/composables/auth/useAuthValidation";
import { storeToRefs } from "pinia";
import { useAuthApi } from "~/composables/auth/useAuthApi";
import { useAuth } from "~/composables/auth/useAuth";
import { useUsersApi } from "~/composables/users/useUsersApi";
import { useFeedback } from "~/composables/feedback/useFeedback";

const feedback = useFeedback()
const authStore = useAuthStore()
const authApi = useAuthApi()
const usersApi = useUsersApi()
const auth = useAuth()
const { connexion } = storeToRefs(authStore)
const authValidation = useAuthValidation()

const $e = await authValidation.stepRegisterName()

async function clickRegisterName() {
  await $e.value.$validate();

  if ($e.value.$error) {
    return ;
  }

  const { data, error } = await usersApi.updateNames({
      firstname: connexion.value.firstname!,
      lastname: connexion.value.lastname!,
  })

  if (!data.value) {
      feedback.error(`Une erreur est survenue`, "small")
      return ;
  }

  if (authStore.user){
    authStore.user.firstname = connexion.value.firstname!
    authStore.user.lastname = connexion.value.lastname!
  }


  return auth.handleExternalRedirect()
}

const firstname = ref<HTMLInputElement>()

onMounted(() => {
  if (firstname.value) {
      firstname.value.focus()
  }
})


</script>

<template>
  <form class="connexion__form" @submit.prevent="clickRegisterName">
    <div class="grid gap-[16px]">
      <div class="connexion__form_input_container">
        <label for="firstname" class="body_med text-secondary-color">Prénom *</label>
        <input v-model="connexion.firstname" id="firstname" ref="firstname" type="text" placeholder="Jules" class="input__default body_med">
          <span v-if="$e.$errors.some(x => x.$property === 'firstname')" class="text-primary-red-color body_med">
          {{ $e.$errors.find(x => x.$property === 'firstname')?.$message }}
        </span>
      </div>


      <div class="connexion__form_input_container">
        <label for="lastname" class="body_med text-secondary-color">Nom *</label>
        <input v-model="connexion.lastname" id="lastname" placeholder="Ferry" type="text" class="input__default body_med">
        <span v-if="$e.$errors.some(x => x.$property === 'lastname')" class="text-primary-red-color body_med">
          {{ $e.$errors.find(x => x.$property === 'lastname')?.$message }}
        </span>
      </div>
    </div>

    <div class="connexion__form_button_container">
      <button class="button__default">Accéder à la plateforme</button>
    </div>
  </form>
</template>

<style lang="scss" scoped>

</style>